import AWN from 'awesome-notifications'
import 'awesome-notifications/dist/style.css'
import $$ from 'jquery'
import Popup from '@/common/Popup'

/*
tip
info
success
warning
alert
async
*/
let awn = new AWN({})

awn.prompt = function (options) {

    let opt = $$.extend(true, {
        templateBody: function (options) {
            return `<label for="input-1" class="exo-popup__label">${options.message}</label>
                            <input id="input-1" type="text" class="exo-popup__input exo-input" data-value="true">
                `
        },
        templateFooter: function (options) {
            return `<button class="exo-popup__cancel exo-btn-light exo-btn-min" data-trigger="CANCEL">${options.labels.CANCEL}</button>
                        <button class="exo-popup__ok exo-btn-primary exo-btn-min" data-trigger="OK">${options.labels.OK}</button>`
        },
        labels: {
            CANCEL: 'CANCEL'
        },
    }, options)

    let popup = new Popup(opt)

    popup.$win.find('.exo-popup__input').focus()

    return popup.promise.then(popup => {
        return popup
    })
}

awn.confirm = function (options) {
    let opt = $$.extend(true, {
        templateFooter: function (options) {
            return `<button class="exo-popup__cancel exo-btn-light exo-btn-min" data-trigger="CANCEL">${options.labels.CANCEL}</button>
                        <button class="exo-popup__ok exo-btn-danger exo-btn-min" data-trigger="OK">${options.labels.OK}</button>`
        },
        labels: {
            CANCEL: 'CANCEL'
        },
        variant: 'danger'
    }, options)

    let popup = new Popup(opt)

    return popup.promise.then(popup => {
        return popup
    })
}

awn.confirmDiscard = function (options) {
    let opt = $$.extend(true, {
        templateFooter: function (options) {
            return `<button class="exo-popup__discard exo-btn-secondary exo-btn-min" style="margin-right: auto" data-trigger="DISCARD">${options.labels.DISCARD}</button>
                        <button class="exo-popup__cancel exo-btn-light exo-btn-min" data-trigger="CANCEL">${options.labels.CANCEL}</button>
                        <button class="exo-popup__ok exo-btn-primary exo-btn-min" data-trigger="OK">${options.labels.OK}</button>
                        `
        },
        labels: {
            DISCARD: 'DISCARD',
            OK: 'SAVE',
            CANCEL: 'CANCEL'
        },
    }, options)

    let popup = new Popup(opt)

    return popup.promise.then(popup => {
        return popup
    })
}

export default awn;
