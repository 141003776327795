export default class ExoForm {
    constructor($form) {
        this.$el = $form
        this.subscribers = []
        //this.initFields();
        $form.on('submit', this.submit.bind(this))
    }

    onSubmit(callback) {
        if (!callback) return
        this.subscribers.push(callback)
    }

    async submit(event) {
        event.preventDefault();
        await this.beforeSubmit()
        let result = await this.callSubscribers(event)
        if (result === false) return
        this.$el[0].submit()
    }

    getFormId() {
        return +this.$el.data('form_id')
    }

    getFormName() {
        return this.$el.data('form_name')
    }

    async callSubscribers(event) {
        for (let subscriber of this.subscribers) {
            let result = await subscriber(event, this.$el.get(0))
            if (result === false) return false
        }
    }

    initCaptcha() {

        let $placeholder = this.$el.find('.g-recaptcha');
        if ($placeholder.length < 1) return
        let siteKey = $placeholder.data('site-key')

        this.captchaWidgetID = grecaptcha.render(
            $placeholder.get(0),
            {
                'sitekey': siteKey,
                'size': 'invisible'
            });
    }

    async runChallenge() {
        if (this.captchaWidgetID === undefined) return
        await grecaptcha.execute(this.captchaWidgetID, {action: 'submit'})
    }

    async beforeSubmit() {
        await this.runChallenge()
    }
}