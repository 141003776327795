import $$ from 'jquery'
import FrameBoxModal from '@/common/FrameBox'
import exoEventBus from '@modules/exoEventBus'

let modal

const exoFrame = {
    open(params) {
        let defaults = {
            height: '90%',
            width: '90%',
            inner: {
                css: {
                    'max-width': '1350px'
                }
            }
        };
        defaults = $$.extend(defaults, params);
        modal = new FrameBoxModal(defaults)
        return modal.open()
    },

    close() {
        if (modal) {
            modal.close()
        }
    },
}

exoEventBus.$on('exolog-exo-modal-cancel', exoFrame.close);

export default exoFrame
