/**
 *
 * This module allows an open vue admin panel in iframe and pushes route with params
 *
 **/
import exoEventBus from '@modules/exoEventBus'
import exoFrame from '@/common/exoFrame/'

let exoBridge = {
    open(params) {
        return new Promise(resolve => {
            exoEventBus.$once('exo-bridge-installed', () => this.push(params))
            exoFrame.open({href: '/exo_admin/bridge'}).then(resolve)
        })
    },

    push(params) {
        //we set level 0 to push message only to child iframe
        //and prevent push to parent, in case when we have few level of iframe
        exoEventBus.$emit('exo-router-push', params, 0)
    },

    replace(params) {
        exoEventBus.$emit('exo-router-replace', params)
    },

    close() {
        exoFrame.close()
    }
}

exoEventBus.$on('exo-popup-header-close-outer', exoBridge.close)

export default exoBridge
