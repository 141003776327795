import $$ from 'jquery'
import 'gasparesganga-jquery-loading-overlay'

$$.LoadingOverlaySetup({
    imageColor: 'rgb(68, 68, 68)',
    imageResizeFactor: 0.75,
    background: 'rgba(238, 238, 238, 0.95)',
    size: 20,
    zIndex: '1050'
});

export default {
    show: function (element = undefined, options={}) {
        if (element) {
            $$(element).LoadingOverlay("show", options);
        } else {
            $$.LoadingOverlay("show");
        }
    },
    hide: function (element = undefined, force = false) {
        if (element) {
            $$(element).LoadingOverlay("hide", force);
        } else {
            $$.LoadingOverlay("hide", force);
        }
    }
}
