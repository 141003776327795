import $ from 'jquery'
import overlay from '@/common/overlay/overlay'
import './frame-box.scss'

export default class FrameBox {
    constructor(options) {
        const defaults = {
            //Url
            href: '',
            height: '90%',
            width: 1024,
            // Close existing modals
            // Set this to false if you do not need to stack multiple instances
            closeExisting: false,

            //Custom css styling for iframe wrpping element
            inner :{
                css:{},
            },

            // Enable keyboard navigation
            keyboard: true,

            // Custom CSS styling for iframe element
            // You can use this to set custom iframe dimensions
            iframe: {
                css: {},

                // Iframe tag attributes
                attr: {
                    scrolling: "auto"
                },
            },

            // Custom CSS class for layout
            baseClass: "",

            // Base template for layout
            baseTpl:
                '<div id="{{id}}" class="frame-box" role="dialog" tabindex="-1">' +
                '<div class="frame-box__backdrop"></div>' +
                '<div class="frame-box__inner">' +
                '<iframe class="frame-box__frame" allow="autoplay; fullscreen" src=""></iframe>' +
                '</div>' +
                '</div>',

            // Error message template
            errorTpl: '<div class="frame-box-error"><p>{{ERROR}}</p></div>',

            // Container is injected into this element
            parentEl: "body",

            // Hide browser vertical scrollbars; use at your own risk
            hideScrollbar: true,

            // Focus handling
            // ==============

            // Try to focus on the first focusable element after opening
            autoFocus: true,

            // Put focus back to active element after closing
            backFocus: true,

            // Do not let user to focus on element outside modal content
            trapFocus: true,

            // Module specific options
            // =======================

            // Set `touch: false` to disable panning/swiping
            touch: {
                vertical: true, // Allow to drag content vertically
                momentum: true // Continue movement after releasing mouse/touch when panning
            },

            // Callbacks
            onInit: $.noop, // When instance has been initialized

            beforeLoad: $.noop, // Before the content of a slide is being loaded
            afterLoad: $.noop, // When the content of a slide is done loading

            beforeShow: $.noop, // Before open animation starts
            afterShow: $.noop, // When content is done loading and animating

            beforeClose: $.noop, // Before the instance attempts to close. Return false to cancel the close.
            afterClose: $.noop, // After instance has been closed

            // Interaction
            // ===========

            // Use options below to customize taken action when user clicks or double clicks on the frame-box area,
            // each option can be string or method that returns value.
            //
            // Possible values:
            //   "close"           - close instance
            //   false             - do nothing
            // Clicked on the background (backdrop) element;
            // if you have not changed the layout, then most likely you need to use `clickSlide` option
            clickOutside: "false",
        }
        this.options = $.extend(defaults, options)

        this.$parent = $(this.options.parentEl)
        this._create()
        this._callCallback(this.options.onInit, this)
    }

    _create() {
        this.iframeId = 'frame-box-' + Date.now();
        let tpl = this.options.baseTpl.replace('{{id}}', this.iframeId)
        this.$modal = $(tpl)
        this.$iframe = this.$modal.find('.frame-box__frame')
        this.$inner = this.$modal.find('.frame-box__inner')
        this.$inner.css('height', this.options.height)
        this.$inner.css('width', this.options.width)
        this.$inner.css(this.options.inner.css)

    }

    async _loadIframe(href) {
        const me = this;
        this._callCallback(me.options.beforeLoad, me);
        this.iframeSrc = href

        //set attribute
        this.$iframe.attr(this.options.iframe.attr).css(this.options.iframe.css)

        return new Promise(resolve => {
            this.$iframe.on("load.fb error.fb", function (e) {
                this.isReady = 1;
                me._callCallback(me.options.afterLoad, me);
                resolve(this)
            });
            this.$iframe.attr('src', this.iframeSrc)
        })
    }

    _callCallback(func, data) {
        if (typeof func !== "function") {
            return;
        }
        const caller = func.bind(this)
        return caller(data);
    }

    async open() {
        this._callCallback(this.options.beforeShow, this)
        this.$parent.addClass('frame-box-parent--load frame-box-parent--open')
        overlay.show(this.$inner, {
            zIndex: '8050'
        })
        this.$modal.appendTo(this.$parent).addClass('frame-box--open').css('opacity', 1)
        await this._loadIframe(this.options.href)
        this.$parent.removeClass('frame-box-parent--load')
        overlay.hide(this.$inner, true)
        this._callCallback(this.options.afterShow, this)
        return new Promise(resolve => {
            this._resolve = resolve
        })
    }

    close() {
        if (this._callCallback(this.options.beforeClose, this) === false) return
        this.$modal.detach()
        this.$parent.removeClass('frame-box-parent--open')
        this._callCallback(this.options.afterClose, this)
        this._resolve(this)
    }
}