import $$ from 'jquery'
import exoBridge from "@/common/exoBridge";
import {R_FILES} from "@modules/routs";
import ExoFileService from "@modules/ExoFileService";

export default {
    $currentTarget: null,

    init() {
        $$('.exo-select-file-btn').on('click', this.onClickSelectBtn.bind(this))
        $$('.exo-clear-file-btn').on('click', this.onClickClearBtn.bind(this))
        $$('.exo-clear-file-input-btn').on('click', this.onClickFileClearBtn.bind(this))

        exoEventBus.$on('exo-filemanager-select.selectFileButton', this.onSelectFile.bind(this))
    },
    onSelectFile(selection) {
        this.$currentTarget.trigger('exo-selected', selection)

        let fileId = null;
        if (!selection || selection.files.length === 0 || selection.files[0].type === 'dir') {
            //exoDialogs.alert('No file selected!');
        } else {
            fileId = ExoFileService.selectionToFileId(selection)
        }
        this.getInput().val(fileId)
        exoBridge.close();
    },
    getInput() {
        const forId = this.$currentTarget.data('for');
        return $$('#' + forId)
    },
    async onClickSelectBtn(e) {
        e.preventDefault()
        this.$currentTarget = $$(e.target)
        const query = {
            namespace: 'selectFileButton',
        }
        const params = this.$currentTarget.data()

        if (params['start-folder']) {
            query.startFolder = params['start-folder']
        }
        if (params['start-disk']) {
            query.startDisk = params['start-disk']
        }
        if (params['folder-back']) {
            query.folderBack = params['folder-back']
        }

        const fileId = this.getInput().val();
        if (fileId) {
            query.startSelection = fileId
        }

        await exoBridge.open({
            name: R_FILES,
            params: {
                mode: 'select'
            },
            query
        })

    },
    async onClickClearBtn(e) {
        e.preventDefault()
        this.$currentTarget = $$(e.target)
        this.getInput().val('')
    },

    async onClickFileClearBtn(e) {
        e.preventDefault()
        let $currentTarget = $$(e.target)
        const name = $currentTarget.data('value-input')
        $currentTarget.closest('form').find(`input[name="${name}"]`).val('')
        const forId = $currentTarget.data('for')
        $$(`label[for="${forId}"].exo-label-file-value`).html('')
        $$('#' + forId).val('')
    }
}
