export const template = function (options) {
    return `<div class="exo-popup__content">
                        ${options.templateHeaderOuter(options)}
                        ${options.templateBodyOuter(options)}
                        ${options.templateFooterOuter(options)}
                </div>`
}
export const templateHeader = function (options) {
    return `<h5 class="exo-popup__title">${options.title}</h5>`
}
export const templateHeaderOuter = function (options) {
    return `<header class="exo-popup__header">
                           ${options.templateHeader(options)}
                        </header>`
}
export const templateBodyOuter = function (options) {
    return `<div class="exo-popup__body">
                            ${options.templateBody(options)}
                        </div>`
}
export const templateBody = function (options) {
    return `${options.message}`
}
export const templateFooter = function (options) {
    return `<button class="exo-popup__ok exo-btn-primary exo-btn-min" data-trigger="OK">${options.labels.OK}</button>`
}
export const templateFooterOuter = function (options) {
    return `<footer class="exo-popup__footer">                           
                                ${options.templateFooter(options)}
                            </footer>`
}